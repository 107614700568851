import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/world'
    },
    {
        path: '/hello',
        component: resolve => require(['../components/Hello.vue'], resolve),
        meta: {
            fullScreen: true
        }
    }, {
        path: '/world',
        component: resolve => require(['../components/World.vue'], resolve),
        meta: {
            fullScreen: true
        }
    }, {
        path: '/result',
        component: resolve => require(['../components/Result.vue'], resolve),
        meta: {
            fullScreen: true
        }
    }
];

export default new Router({
    routes,
})